.upload {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    padding: 0 20px;

    .body {
        flex: 1;

        label {
            display: block;
            font-size: 17px;
            font-weight: bold;
            margin-bottom: 8px;
        }

        select {
            width: 230px;
            height: 40px;
            padding: 6px;
            font-size: 17px;
        }

        h3 {
            margin-top: 30px;
            margin-bottom: 10px;
        }

        P {
            margin-top: 0;
            font-size: 15px;
        }

        .drag {
            min-height: 300px;
            border-radius: 10px;
            border: 2px dashed #aaa;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: bold;
            color: #aaa;
            font-size: 25px;
            margin-top: 25px;
            position: relative;

            input {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }
    }

    .footer {
        height: 90px;
        text-align: right;
    }
}
