.app {
    max-width: 950px;
    width: 100%;
    margin: auto;

    .logo {
        margin-top: 40px;
        margin-bottom: 10px;

        img {
            height: 50px;
        }
    }

    .app-body {
        min-height: 600px;
        background: white;
        position: relative;
        box-shadow: 0px 7px 9px rgb(0 0 0 / 7%);
        border-radius: 6px;
        padding: 20px;
        border-bottom: 2px solid #000;
    }
}

.btn {
    border: 0px;
    padding: 13px 40px;
    color: #ffffff;
    font-size: 19px;
    background: rgb(0, 217, 150);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 40px;
    font-weight: bold;
}
