.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;

    img {
        width: 70px;
        margin-top: -40px;
    }

    h2 {
        margin-bottom: 5px;
    }

    p {
        margin-bottom: 50px;
        line-height: 25px;
    }
}
