.results {
    button {
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 16px;
    }

    h3 {
        margin-top: 8px;
    }

    .body {
        border-top: 1px solid #ccc;
        margin-top: 30px;
        background: rgb(249, 249, 249);
        box-shadow: 0 3px 0 #fff inset;
        height: 480px;
        overflow: scroll;
        padding-top: 5px;
        padding-left: 5px;

        .item {
            border: 1px solid;
            display: inline-block;
            width: 286px;
            height: 200px;
            margin: 5px;
            position: relative;
            cursor: pointer;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            .filename {
                color: #fff;
                font-size: 11px;
                position: absolute;
                bottom: 5px;
                left: 5px;
                max-width: 190px;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                text-shadow: 1px 0px rgb(0 0 0 / 48%);
            }

            &::before {
                content: 'Default';
                position: absolute;
                bottom: 0;
                right: 0;
                padding: 5px 10px;
                background-color: #000;
                color: #fff;
                min-width: 60px;
                text-align: center;
            }

            &.pass {
                border: 2px solid rgb(0, 217, 150);
                &::before {
                    content: 'Pass';
                    background-color: rgb(0, 217, 150);
                }
            }

            &.fake {
                border: 2px solid rgb(218, 34, 68);
                &::before {
                    content: 'Fake';
                    background-color: rgb(218, 34, 68);
                }
            }
        }
    }

    .img-preview {
        position: fixed;
        width: 100%;
        height: 100%;
        background: #0009;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        align-content: center;
        text-align: center;
        overflow: auto;

        .img-body {
            margin: auto;

            img {
                max-width: 950px;
            }
        }
    }
}
